/* eslint-disable no-unused-vars */
import {
  Box,
  Grid,
  Button,
  Divider,
  Snackbar,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { styled } from "@mui/material/styles";
import {
  Alert,
  BoxPaper,
  DashPaper,
  StyledTableCell,
  StyledTableErrorCell,
  Title,
} from "../components/styledComponents";
import { Footer, ResponsiveAppBar } from "../components/common";
import DataTable from "../components/table";
import { columnHeaders } from "../data/content";
import { SessionContext } from "../providers/provider";
import { getHCPDetails, importHcpDetails } from "../services/hcpmappingservice";
import BackdropLoader from "../components/loader";
import ExcelJS from "exceljs";
import { HcpFilter } from "../components/commonSearch";
import * as XLSX from "xlsx";
import { getHCPExportData } from "../services/hcpmappingservice";
import { useResolvedPath } from "react-router-dom";

function HcpData() {
  const userSession = useContext(SessionContext);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [importData, setImportData] = useState([]);
  const [isUpdate, setIsUpdate] = useState(true);
  const [alert, setAlert] = useState({ message: "", type: "" });
  const [alertOpen, setAlertOpen] = useState(false);
  const [modalOpen, setModelOpen] = useState(false);
  const [errorTable, setErrorTable] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isExporting, setIsExporting] = useState(false);
  const [excelData, setExceldata] = useState({});
  const increment = 50;
  const limit = 50;

  const handleOpen = () => setModelOpen(true);
  const handleClose = () => setModelOpen(false);

  const VisuallyHiddenInput = styled("input")`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
  `;

  const modal_style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
  };

  const getAllData = (accessToken, filters) => {
    let calls = [];
    let loopcount = Math.ceil(data.length / increment);
    for (let i = 0; i < loopcount; i++) {
      calls.push(getHCPExportData(accessToken, filters, i * increment, limit));
    }
    let totalProgress = 0;
    calls.forEach((call) => {
      call.then((batchData) => {
        const batchDataLength = batchData.result[0]?.length;
        const progress = (batchDataLength / data.length) * 100;
        totalProgress += progress;
        setProgress(Math.round(totalProgress));
      });
    });
    return calls;
  };

  useEffect(() => {
    if (userSession.userName !== "") {
      let accessToken = localStorage.getItem("apitoken");
      async function fetchData() {
        setIsLoading(true);
        const response = await getHCPDetails(accessToken);
        // let filterresponse = await getFilteredHcp(accessToken,{national_id:null,professional_id:null,customer_id:null,hcp_name:getBottomNavigationUtilityClass})
        setData(response.result[0]);
        setAllData(response.result[0]);
        setIsLoading(false);
      }
      fetchData();
    }
  }, [userSession]);

  let callback = (valueFromSearch, filteredData) => {
    setData(valueFromSearch);
    setExceldata(filteredData);
  };

  async function handleDownloadExcel() {
    const tableHeader = [
      "hcp_firstname",
      "hcp_lastname",
      "national_id",
      "professional_id",
      "email_id",
      "customer_id",
    ];
    // create a workbook
    const workbook = new ExcelJS.Workbook();
    // create a wroksheet
    const hcpWorksheet = workbook.addWorksheet("sampletemplate");
    let headerarr = [
      { key: "", width: 20 },
      { key: "", width: 20 },
      { key: "", width: 20 },
      { key: "", width: 20 },
      { key: "", width: 20 },
      { key: "", width: 20 },
    ];
    hcpWorksheet.columns = headerarr;
    hcpWorksheet.getRow(1).values = tableHeader;
    const blobdata = await workbook.xlsx.writeBuffer();
    const url = window.URL.createObjectURL(new Blob([blobdata]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Upload Excel Sample Template.xlsx`);
    document.body.appendChild(link);
    link.click();
  }

  const handleFileUpload = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      setImportData(parsedData);

      const hcpData = [];
      for (let i = 0; i < parsedData.length; i++) {
        hcpData.push({
          hcp_firstname: `${parsedData[i].hcp_firstname ? parsedData[i].hcp_firstname.toString().trim() : ''}`,
          hcp_lastname: `${parsedData[i].hcp_lastname ? parsedData[i].hcp_lastname.toString().trim() : ''}`,
          national_id: `${parsedData[i].national_id ? parsedData[i].national_id.toString().trim() : ''}`,
          professional_id: `${parsedData[i].professional_id ? parsedData[i].professional_id.toString().trim() : ''}`,
          email_id: `${parsedData[i].email_id ? parsedData[i].email_id.toString().trim() : ''}`,
          customer_id: `${parsedData[i].customer_id ? parsedData[i].customer_id.toString().trim() : ''}`,
        });
      }
      const apiToken = localStorage.getItem("apitoken");
      if (hcpData.length === 0) {
        setIsUpdate(true);
        setIsLoading(true);
        handleAlertClick(
          `Import Failed.Please upload valid excel sheet.`,
          "error"
        );
        setIsLoading(false);
      } else {
        setIsLoading(true);
        importHcpDetails(apiToken, hcpData, userSession.userId).then(
          (Response) => {
            setIsLoading(false);
            const { message, statusCode, errorData } = Response;
            setErrorTable(errorData);
            if (statusCode === 403) {
              setIsUpdate(true);
              // handleAlertClick(
              //   `Import Failed! ${errorData[0].error} records found for ${errorData[0].column}`,
              //   "error"
              // );
              handleOpen();
            } else if (message === "Successful") {
              setIsUpdate(true);
              handleAlertClick(`Data uploaded successfully`, "success");
              setIsLoading(true);
              getHCPDetails(apiToken)
                .then((res) => {
                  setData(res.result[0]);
                  setAllData(res.result[0]);
                })
                .catch((err) => {
                  console.log("Error err", err);
                });
              async function fetchData() {
                setIsLoading(true);
                const response = await getHCPDetails(
                  localStorage.getItem("apitoken")
                );
                setData(response.result[0]);
                setAllData(response.result[0]);
                setIsLoading(false);
              }
              fetchData();
            }
          }
        );
      }
    };
  };

  // Added download functionality for HCP data
  async function handleExport() {
    let accessToken = localStorage.getItem("apitoken");
    let filters = {
      hcp_name: excelData?.hcp_name,
      national_id: excelData?.national_id,
      professional_id: excelData?.professional_id,
      customer_id: excelData?.customer_id,
      lilly_id: null,
    };
    setIsLoading(true);
    setIsExporting(true);
    Promise.all(getAllData(accessToken, filters))
      .then(async (response) => {
        let allData = [];
        for (let i = 0; i < response.length; i++) {
          let item = response[i];
          if (item.result && item.message === "Successful" && item.result[0]?.length > 0) {
            allData = [...allData, ...item.result[0]];
          }
        }
        const workbook = new ExcelJS.Workbook();
        const hcpWorksheet = workbook.addWorksheet("allData");
        userSession.userRole === "Admin" ?
          (hcpWorksheet.getRow(1).values = columnHeaders.hcpAdminHeader)
          : (hcpWorksheet.getRow(1).values = columnHeaders.hcpMOHeader);
        hcpWorksheet.getRow(1).height = 30;
        hcpWorksheet.getRow(1).font = {
          family: 4,
          size: 12,
          bold: true,
        };
        hcpWorksheet.getRow(1).alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        };
        let adminarr = [
          { key: "hcp_name", width: 40 },
          { key: "national_id", width: 40 },
          { key: "professional_id", width: 40 },
          { key: "email_id", width: 40 },
          { key: "hcp_mobile", width: 40 },
          { key: "customer_id", width: 40 },
        ];
        let moarr = [
          { key: "hcp_name", width: 40 },
          { key: "customer_id", width: 40 },
          { key: "tov_status", width: 40 }
        ];
        userSession.userRole === "Admin" ?
          hcpWorksheet.columns = adminarr : hcpWorksheet.columns = moarr;
        allData.forEach((x, i) => {
          const rowNumber = i + 1;
          x.hcp_mobile = (x.hcp_mobile === "-1" || x.hcp_mobile == null) ? "NA" : x.hcp_mobile;
          x.customer_id = x.customer_id ? x.customer_id : "NA";
          x.tov_status = (x.national_id && x.professional_id) ? "Y" : "N";
          let row = hcpWorksheet.addRow(x);
          row.alignment = {
            horizontal: "center",
            vertical: "middle",
            wrapText: true
          };
          row.height = 30;
        });

        const blobdata = await workbook.xlsx.writeBuffer();
        const url = window.URL.createObjectURL(new Blob([blobdata]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `HCP_Export.xlsx`);
        document.body.appendChild(link);
        link.click();
        setIsLoading(false);
        setProgress(0);
        handleAlertClick("Downloaded Successfully", "success");
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
        setIsExporting(false);
        handleAlertClick("Issue in downloading excel", "error");
      });
  }

  const handleAlertClick = (message, type) => {
    setAlert({
      message,
      type,
    });
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity={alert.type}>
          {alert.message}
        </Alert>
      </Snackbar>
      <ResponsiveAppBar />

      <>
        <BoxPaper>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <DashPaper sx={{ pb: 4, pt: 1 }}>
                <Grid item xs={12} sm={12} md={12} textAlign={"center"}>
                  <Title sx={{ color: "#1976d2", fontWeight: "bold" }}>
                    View HCP Details
                  </Title>
                </Grid>
                <HcpFilter
                  data={data}
                  allData={allData}
                  callbackFunc={callback}
                />
                <Divider />
                <Grid item xs={12} md={12}>
                  <Grid
                    mt={1}
                    spacing={2}
                    container
                    justifyContent={"flex-end"}
                    sx={{ pr: 5 }}
                  >
                    {(userSession.userRole === "Admin") && (
                      <>
                        <Grid item>
                          <Button
                            variant="contained"
                            onClick={handleDownloadExcel}
                            sx={{ fontWeight: "bold" }}
                          >
                            Sample Template
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            component="label"
                            variant="contained"
                            href="#file-upload"
                            sx={{ fontWeight: "bold" }}
                          // onClick={handleOpen}
                          >
                            Upload Data
                            <VisuallyHiddenInput
                              required
                              type="file"
                              accept=".xlsx "
                              onChange={handleFileUpload}
                            />
                          </Button>
                        </Grid>
                      </>
                    )}
                    <Grid item>
                      <Button
                        variant="contained"
                        sx={{ fontWeight: "bold" }}
                        onClick={handleExport}
                      >
                        Export to Excel
                      </Button>
                    </Grid>
                  </Grid>
                  {isLoading ? (
                    <BackdropLoader show={isLoading}
                      isExporting={isExporting}
                      value={progress}
                    />
                  ) : (
                    <Box m={3} mt={1}>
                      {userSession.userRole === "Admin" ?
                        <DataTable
                          rows={data}
                          tableHeader={columnHeaders.viewHCPAdminHeader}
                        /> :
                        <DataTable
                          rows={data}
                          tableHeader={columnHeaders.viewHCPMOHeader}
                        />
                      }
                    </Box>
                  )}
                </Grid>
              </DashPaper>
            </Grid>
          </Grid>

          {/* modal  */}
          <Modal
            open={modalOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modal_style}>
              <Typography
                id="parent-modal-title"
                variant="h6"
                fontWeight={"bold"}
                sx={{
                  textAlign: "center",
                }}
              >
                Errors in Uploaded Data
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        backgroundColor: "#1976d2",
                        height: "20px",
                      }}
                    >
                      <StyledTableCell>Row</StyledTableCell>
                      <StyledTableCell>Column</StyledTableCell>
                      <StyledTableCell>Error</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {errorTable?.map((e) => (
                      <TableRow>
                        <StyledTableErrorCell>{e.row}</StyledTableErrorCell>
                        <StyledTableErrorCell>
                          {e.column}
                        </StyledTableErrorCell>
                        <StyledTableErrorCell>{e.error}</StyledTableErrorCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                sx={{
                  textAlign: "right",
                  padding: "16px",
                }}
              >
                <Button variant="contained" onClick={handleClose}>
                  Close
                </Button>
              </Box>
              <Box>
                <Typography color={"red"}>
                  Please remove the above errors and upload file again.{" "}
                </Typography>
              </Box>
            </Box>
          </Modal>
        </BoxPaper>
        <Footer />
      </>

    </>
  );
}
export default HcpData;
